import { useState, useEffect, useMemo, useCallback } from 'react'
import { firestore } from '../lib/firebase'
import useAuth from '../customHooks/useAuth'
import useMessenger from '../customHooks/useMessenger'

export const defaultLanguage = 'nl'
export const validLanguages = [defaultLanguage, 'fr']

const useStyles = (page) => {
  const bl = (navigator.language || navigator.userLanguage).slice(
    0,
    2,
  )
  const browserLanguage = validLanguages.includes(bl) ? bl : null
  const { db, getDoc, doc, setDoc } = firestore
  const { currentUser, storageUser } = useAuth()
  const { sendMessageToVista } = useMessenger()

  const [language, setLanguage] = useState(window.localStorage.getItem('language') || browserLanguage || defaultLanguage)
  const [isTextsLoading, setIsTextsLoading] = useState(true)
  const [texts, setTexts] = useState({})

  const changeLanguage = (newLang) => {
    setDoc(
      doc(db, 'users', currentUser.email),
      { language: newLang },
      { merge: true },
    ).then(() => setLanguage(newLang))
  }

  useEffect(() => {
    window.localStorage.setItem('language', language)
    sendMessageToVista({
      action: 'switch-language',
      language,
    })
  }, [language, sendMessageToVista])

  useEffect(
    () =>
      getDoc(doc(db, 'texts', page || 'login'))
        .then((snapshot) => {
          setTexts(snapshot.data())
          setIsTextsLoading(false)
        })
        .catch(console.error),
    [db, doc, getDoc, page],
  )

  useEffect(() => {
    if (storageUser?.language) {
      setLanguage(storageUser.language)
    }
  }, [storageUser?.language])

  const locale = useMemo(() => {
    const res = {}
    Object.keys(texts).forEach((text) => {
      res[text] = texts[text][language]
    })
    return res
  }, [language, texts])

  const txt = useCallback(
    (name) => {
      return locale[name] || `${language.toUpperCase()}: ${name}`
    },
    [language, locale],
  )

  // console.log({ texts, txt })
  return {
    isTextsLoading,
    language,
    txt,
    validLanguages,
    setLanguage,
    changeLanguage,
  }
}

export default useStyles
