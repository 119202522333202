import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'

const Wrapper = styled.div`
  width: 1680px,
  height: 720px;
  max-height: 720px;
  display: grid;
  grid-template-columns: 1280px 306px;
  overflow: hidden;
`

export const VideoMenu = styled.div`
  background: #b7c8cf;
  border-left: 2px solid #91a4ab;
`

export const VideoContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`

export const overlayCss = cx(css`
  position: absolute;
  top: 55px;
  padding: 15px 10px;
  font-size: 18px !important;
  border-radius: 10px;
  min-width: 200px;
  max-width: 400px;
  text-align: center;
  color: #fff !important;
  text-decoration: none;
  transition: opacity 0.3s ease-in;
  opacity: 0;
  box-shadow: 0;
  overflow: hidden;
  background-color: #007a97 !important;

  &.show {
    opacity: 1;
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  }

  &.link-to {
    left: 100px;
  }
  &.link-back {
    left: 1000px;
  }
  &.link-url {
    left: 100px;
  }
`)

export default Wrapper