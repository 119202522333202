import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin: auto;
  width: 400px;
  height: 225px;
  overflow: hidden;
`

export const Container = styled.div`
  border-top: 2px solid #000;
  margin: 10px 0 20px 0;
  padding-top: 15px;
  &:first-of-type {
    border: 0;
  }
  display: flex;
  align-items: center;
`

export const LectureButton = styled.button`
  display: block;
  border-radius: 0;
  background: #98ce01;
  text-decoration: none;
  border: 0;
  width: 210px;
  height: 36px;
  padding: 5px 10px;
  margin: 3px 0 10px 0;
  color: #fff;
  font-weight: 200;
  font-style: italic;

  &:disabled {
    background: #cccccc;
  }

  &[name='active'] {
    background: #0a747f;
  }

  & > div {
    color: #fff;
    font-size: 18px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
    text-transform: uppercase;
    font-style: italic;
  }
`

export const Presenter = styled.div`
  font-size: 20px;
  font-weight: 500px;
  text-align: center;
  width: 210px;
`

export const Description = styled.div`
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  width: 210px;
`

export default Wrapper
