import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'

import { sendEmailVerification } from '../../../lib/firebase'

import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import useTexts from '../../../customHooks/useTexts'
import useMessenger from '../../../customHooks/useMessenger'
import GlobalStyles from '../../Styling'

export default function Login() {
  const { action } = useParams()

  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, currentUser } = useAuth()
  const { isStylesLoading, style } = useStyles('unauthorizedStyles')
  const { isTextsLoading, txt, setLanguage, language } = useTexts('login')
  const { sendMessageToVista } = useMessenger()

  const [error, setError] = useState('')
  const [resent, setResend] = useState(0)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if ((currentUser && !currentUser.emailVerified) || action === 'verify') {
      setResend(1)
      setError(txt('errorNotVerified'))
    } else if (currentUser && currentUser.emailVerified) {
      history.push('/profile')
    }
  }, [currentUser, txt, action, history])

  const handleSubmit = (e) => {
    e.preventDefault()

    setError('')
    setLoading(true)
    login(emailRef.current.value, passwordRef.current.value)
      .then(({ user }) => {
        if (user && user.emailVerified) {
          history.push('/profile')
          sendMessageToVista({ action: 'do-login' })
          return
        }
        setResend(1)
        setError(txt('errorNotVerified'))
      })
      .catch(() => setError(txt('failedToLogin')))

    setLoading(false)
  }

  return isStylesLoading || isTextsLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">{txt('headline')}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {resent === 1 && (
        <Button
          className="w-100 mt-2 mb-4"
          type="button"
          onClick={() => {
            sendEmailVerification(currentUser)
            setResend(2)
          }}
        >
          {txt('resendVerificationMail')}
        </Button>
      )}
      {resent === 2 && (
        <Alert variant="success">{txt('verificationMailSentAgain')}</Alert>
      )}
      <div className="w-100">
        <Form onSubmit={handleSubmit}>
          <Form.Group id="email">
            <Form.Label>{txt('email')}</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Form.Group id="password">
            <Form.Label>{txt('password')}</Form.Label>
            <Form.Control type="password" ref={passwordRef} required />
          </Form.Group>
          <Button disabled={loading} className="w-100 mt-4" type="submit">
            {txt('buttonLogin')}
          </Button>
          <Button
            className="btn btn-primary w-100 mt-3"
            onClick={() => setLanguage(language === 'fr' ? 'nl' : 'fr')}
          >
            {txt('switchLanguage')}
          </Button>
        </Form>
      </div>
      <div className="w-100 text-center mt-3">
        <Link to="/forgot">{txt('forgot')}</Link>
      </div>
      <div className="w-100 text-center mt-2">
        {txt('account')} <Link to="/signup">{txt('signUp')}</Link>
      </div>
    </GlobalStyles>
  )
}
