import React from 'react'

import Loading from './Loading'
import Header from './Header'
import Navigation from './Navigation'


export default function Surface({ roles, children }) {

  return (
    <div>
      {children}
      <Loading />
      <Navigation roles={roles} />
      <Header />
    </div>
  )
}
