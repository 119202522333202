import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import useAuth from '../../customHooks/useAuth'
import Dashboard from './Content/Dashboard'
import Surface from './Surface'
import './App.css'

export default function Admin() {
  const { currentUser, storageUser } = useAuth()

  if (!currentUser || !storageUser || !storageUser.roles) {
    return null
  }

  const { roles } = storageUser

  return (
    <Surface roles={roles}>
      <Router>
        <Switch>
          {roles.includes('dashboard') && (
            <Route exact path="/admin/dashboard" component={Dashboard} />
          )}
        </Switch>
      </Router>
    </Surface>
  )
}

/* {roles.includes('news') && pathname === '/admin/news' && <News />}
{roles.includes('users') && pathname === '/admin/users' && <Users />}
{roles.includes('settings') && pathname === '/admin/settings' && (
  <Settings />
)}
 */
