import styled from '@emotion/styled'

const GlobalStyles = styled.div`
  margin: auto;

  h1,
  h2,
  h3 {
    color: #99cc00;
  }
  div {
    color: #6c7577;
  }
  a {
    color: #99cc00 !important;
    font-weight: 200;
  }
  a.btn {
    color: #fff !important;
    background-color: #99cc00 !important;
    border: 0 !important;
    border-radius: 0 !important;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 200;
  }
  a.btn:hover, a.btn:active, a.btn:focus {
    background-color: #33727E !important;
  }
  button {
    color: #fff !important;
    background-color: #99cc00;
    border: 0 !important;
    border-radius: 0 !important;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 200;

    &:disabled {
      background: #cccccc;
    }
  }
  button:hover, button:active, button:focus {
    background-color: #33727E !important;
  }
  input {
    border: 1px solid #99cc00 !important;
    font-family: 'Helvetica';
  }
`

export default GlobalStyles
