const useStyles = () => {
  /* const frameMessenger = (event, local = false) => {
    let data = {}
    if (local) {
      data = JSON.parse(event)
    } else {
      if (
        ![
          'https://parkinson-liga.web.app',
          'https://parkinsonacademy.be',
          'http://localhost:3000',
          'http://localhost:5000',
        ].includes(event.origin) ||
        !event.data
      ) {
        return
      }
      try {
        data = JSON.parse(event.data)
      } catch (e) {
        return
      }
    }

    if (data.type !== 'parkinson') {
      return
    }
    const { action, language } = data
    switch (action) {
      case 'switch-language':
        console.log('Vista:', `Switch language to ${language}`)
        break
      case 'do-login':
        console.log('Vista:', 'Log in!')
        break
      case 'do-logout':
        console.log('Vista:', 'Log out!')
        break
      default:
        return
    }
  } */

  const addMessageListener = () => {
    if (window.location.origin.indexOf('localhost') === -1) {
      return
    }
    // window.addEventListener('message', frameMessenger, false)
  }

  const sendMessageToVista = (msg) => {
    /* if (window.location.origin.indexOf('localhost') !== -1) {
      frameMessenger(JSON.stringify({ ...msg, type: 'parkinson' }), true)
      return
    } */
    window.parent.postMessage(
      JSON.stringify({ ...msg, type: 'parkinson' }),
      'https://parkinsonacademy.be',
    )
  }
  return { addMessageListener, sendMessageToVista }
}

export default useStyles
