import React, { useContext, useState, useEffect, useCallback } from 'react'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword as upx,
  deleteUser,
  firestore,
} from '../lib/firebase'

export const AuthContext = React.createContext()

export function AuthProvider({ children }) {
  const auth = getAuth()
  const {
    db,
    doc,
    getDoc,
    setDoc,
    deleteDoc,
    addDoc,
    collection,
    getDocs,
    query,
    where,
  } = firestore
  const [currentUser, setCurrentUser] = useState()
  const [storageUser, setStorageUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return upx(currentUser, password)
  }

  const refreshStorageUser = useCallback(() => {
    if (currentUser?.email) {
      getDoc(doc(db, 'users', currentUser.email)).then((snapshot) =>
        setStorageUser(snapshot.data()),
      )
    }
  }, [currentUser?.email, db, doc, getDoc])

  useEffect(() => {
    if (!storageUser?.email) {
      return
    }
    // add login
    const datestr = new Date().toISOString().split('T')[0]
    const logins = storageUser?.logins || []
    if (!logins.includes(datestr)) {
      logins.push(datestr)
      setDoc(
        doc(db, 'users', storageUser.email),
        { logins },
        { merge: true },
      ).then(() => refreshStorageUser())
    }
  }, [
    collection,
    db,
    doc,
    getDocs,
    query,
    refreshStorageUser,
    setDoc,
    storageUser,
  ])

  useEffect(() => {
    if (!storageUser?.email) {
      return
    }
    // remove deleted users
    async function deleteUsers() {
      const u = []
      const q = query(collection(db, 'users'), where('deleted', '==', true))
      await getDocs(q).then((querySnapshot) =>
        querySnapshot.forEach((doc) => {
          u.push({ ref: doc.ref, email: doc.id, ...doc.data() })
        }),
      )
      // console.log('deleted users')
      if (u.length > 0) {
        u.forEach((user) => {
          // console.log('addDoc', user)
          addDoc(collection(db, 'users_deleted'), user)
          // console.log('await deleteDoc', user.ref.id)
          deleteDoc(doc(db, 'users', user.ref.id))
        })
      }
    }
    deleteUsers()
  }, [addDoc, collection, db, deleteDoc, doc, getDocs, query, refreshStorageUser, setDoc, storageUser, where])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser?.email) {
      getDoc(doc(db, 'users', currentUser.email)).then((snapshot) => {
        setStorageUser(snapshot.data())
      })
    }
  }, [currentUser, db, doc, getDoc])

  const value = {
    currentUser,
    storageUser,
    refreshStorageUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    deleteUser,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
