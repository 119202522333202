import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'

const Wrapper = styled.div`
  width: 1680px,
  height: 720px;
  max-height: 720px;
  display: grid;
  grid-template-columns: 1280px 306px;
  overflow: hidden;
  background: #d5e2e8;
`

export const VideoMenu = styled.div`
  padding: 20px;
  z-index: 1;
`

export const Presenter = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
`

export const Description = styled.div`
  font-weight: 300;
  font-size: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid #62727b;
  height: 120px;
`

export const Chapters = styled.div`
  height: 320px;
`

export const Accreditation = styled.div`
  border-top: 2px solid #62727b;
  padding: 5px 0;
  border-bottom: 2px solid #62727b;
`

export const Duration = styled.div`
  text-align: right;
  color: #212528;
  font-size: 11px;
  margin-top: -8px;
`

export const Extra = styled.div`
  padding-top: 10px;
`

export const LectureButton = styled.button`
  display: block;
  margin-bottom: 10px;
  border-radius: 0;
  background: #98ce01;
  text-decoration: none;
  border: 0;
  width: 100%;
  margin-top: 10px;
  height: 36px;
  text-align: left;
  padding: 5px 10px;

  &:disabled {
    background: #cccccc;
  }

  &[name='active'] {
    background: #0a747f;
  }

  & > div {
    color: #fff;
    font-size: 18px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
    text-transform: uppercase;
    font-style: italic;
  }
`

export const LectureLink = styled.a`
  display: block;
  margin-bottom: 10px;
  border-radius: 0;
  background: #98ce01;
  border: 0;
  width: 100%;
  margin-top: 10px;
  height: 36px;
  text-align: left;
  padding: 5px 10px;
  text-decoration: none;

  &:disabled {
    background: #cccccc;
  }

  &[name='active'] {
    background: #0a747f;
  }

  & > div {
    color: #fff;
    font-size: 18px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
    text-transform: uppercase;
    font-style: italic;
  }
`

export const VideoContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`

export const overlayCss = cx(css`
  position: absolute;
  top: 55px;
  font-size: 18px !important;
  border-radius: 10px;
  text-align: center;
  color: #fff !important;
  text-decoration: none;
  transition: opacity 0.3s ease-in,
              background-color 0.3s ease-in;
  opacity: 0;
  box-shadow: 0;
  overflow: hidden;
  font-style: italic;
  font-weight: 200;

  &.show {
    opacity: 1;
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  }

  &.questionaire {
    border-radius: 0;
    background: #98ce01;
    border: 0;
    left: 78px;
    width: 40px;
    height: 40px;

    &:disabled {
      background: #de654e;
    }

    &[name='active'] {
      background: #0a747f;
    }
  }
`)

export default Wrapper
