import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { firestore } from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'
import useMessenger from '../../../customHooks/useMessenger'

export default function Download() {
  const { storageUser } = useAuth()
  const { db, doc, setDoc } = firestore
  const { sendMessageToVista } = useMessenger()
  const { filename } = useParams()

  useEffect(
    () => {
      if (!storageUser || !storageUser.email) {
        return
      }
      const downloads = storageUser.downloads || []
      if (!downloads.includes(filename)) {
        downloads.push(filename)
        setDoc(
          doc(db, 'users', storageUser.email),
          { downloads },
          { merge: true },
        ).then(() => sendMessageToVista({ action: 'downloaded', filename }))
      }
    },
    [db, doc, filename, sendMessageToVista, setDoc, storageUser],
  )

  return <div />
}
