import React, { useEffect } from 'react'
import { ThemeProvider } from '@emotion/react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { AuthProvider } from '../customHooks/useAuth'
import { MenuProvider } from '../customHooks/useMenu'

import useTheme from '../lib/theme'
import useColorScheme from '../customHooks/useColorScheme'
import useMessenger from '../customHooks/useMessenger'

import PrivateRoute from './frontend/PrivateRoute'

import Profile from './frontend/Profile'
import Overview from './frontend/Overview'
import Billboard from './frontend/Billboard'
import Quiz from './frontend/Quiz'
import Video from './frontend/Video'
import Lecture from './frontend/Lecture'
import Update from './frontend/Update'
import Password from './frontend/Password'
import Download from './frontend/Download'

import Login from './frontend/Login'
import Signup from './frontend/Signup'
import Forgot from './frontend/Forgot'
import Validate from './frontend/Validate'

import Admin from './admin'

function App() {
  const colorScheme = useColorScheme()
  const theme = useTheme(colorScheme)
  const { addMessageListener } = useMessenger()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => addMessageListener(), [])

  return (
    <MenuProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/overview" component={Overview} />
              <PrivateRoute exact path="/quiz" component={Quiz} />

              <PrivateRoute exact path="/billboard" component={Billboard} />
              <PrivateRoute path="/billboard/:lectureId" component={Billboard} />
              <PrivateRoute path="/download/:filename" component={Download} />

              <PrivateRoute path="/lecture/:lectureId" exact component={Lecture} />
              <PrivateRoute
                path="/lecture/:lectureId/:chapterId"
                exact
                component={Lecture}
              />

              <PrivateRoute path="/video/:vid" exact component={Video} />
              <PrivateRoute
                path="/video/:vid/:chapterId"
                exact
                component={Video}
              />
              <PrivateRoute
                exact
                path="/video/:vid/:chapterId/:overlayId"
                component={Video}
              />
              <PrivateRoute
                exact
                path="/video/:vid/:chapterId/:overlayId/:timeIn"
                component={Video}
              />

              <PrivateRoute path="/update" component={Update} />
              <PrivateRoute path="/password" component={Password} />
              <PrivateRoute path="/admin" component={Admin} />

              <Route exact path="/">
                <Redirect to="/profile" />
              </Route>
              <Route path="/login" exact component={Login} />
              <Route path="/login/:action" exact component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/validate" component={Validate} />
            </Switch>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </MenuProvider>
  )
}

export default App
