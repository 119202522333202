import { useState, useEffect } from 'react'
import { firestore } from '../lib/firebase'

const useStyles = (name) => {
  const { db, getDoc, doc } = firestore

  const [isStylesLoading, setIsStylesLoading] = useState(true)
  const [style, setStyle] = useState({})

  useEffect(
    () =>
      getDoc(doc(db, 'config', name || 'generalStyles'))
        .then((snapshot) => {
          setStyle(snapshot.data())
          setIsStylesLoading(false)
        })
        .catch(console.error),
    [db, doc, getDoc, name],
  )

  return { isStylesLoading, style }
}

export default useStyles
