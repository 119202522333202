import React, { useState, useCallback, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { firestore } from '../../../lib/firebase'

import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import GlobalStyles from '../../Styling'

export default function Dashboard() {
  const [loading, setLoading] = useState(true)
  const { currentUser } = useAuth()
  const { isStylesLoading, style } = useStyles('videoStyles')
  const { db, collection, getDocs, query } = firestore
  const [videos, setVideos] = useState()

  const getVideos = useCallback(async () => {
    const res = []
    await getDocs(query(collection(db, 'videos'))).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        res.push({ id: doc.id, ...doc.data() })
      }),
    )
    return res
  }, [collection, db, getDocs, query])

  const load = useCallback(async () => {
    setVideos(await getVideos())
    setLoading(false)
  }, [getVideos])

  useEffect(() => {
    if (currentUser) {
      load()
    }
  }, [currentUser, load])

  return loading || isStylesLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">Video Overview</h2>
      {videos && videos.length > 0 && (
        <>
          {videos.map(({ name, chapters, id: videoId }, vdx) => (
            <Row key={vdx}>
              <Col xs={3}>
                <div style={{ padding: '10px' }}>{name}</div>
              </Col>
              <Col xs={9}>
                <div className="flex">
                  {(chapters || []).map(({ head, id }, sdx) => (
                    <Link
                      key={sdx}
                      to={`/video/${videoId}/${id}`}
                      className="btn btn-primary mx-1"
                    >
                      {head}
                    </Link>
                  ))}
                </div>
              </Col>
            </Row>
          ))}
        </>
      )}
    </GlobalStyles>
  )
}
