import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Alert } from 'react-bootstrap'

import {
  reauthenticateWithCredential,
  auth,
  firestore,
} from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import useTexts from '../../../customHooks/useTexts'
import useMessenger from '../../../customHooks/useMessenger'
import GlobalStyles from '../../Styling'

import { CSVLink } from 'react-csv'

const headers = [
  { key: 'lastName', label: 'last name' },
  { key: 'firstName', label: 'first name' },
  { key: 'email', label: 'email' },
  { key: 'gender', label: 'gender' },
  { key: 'language', label: 'language' },
  { key: 'birthDate', label: 'date of birth' },
  { key: 'signupDate', label: 'date of signup' },
  { key: 'riziv', label: 'riziv' },
  { key: 'newsletter', label: 'newsletter' },
  { key: 'visits', label: 'number of visits' },
  { key: 'downloads', label: 'downloaded files' },
]

export default function Profile() {
  const history = useHistory()

  const { EmailAuthProvider } = auth
  const { db, doc, getDoc, setDoc, collection, getDocs } = firestore
  const { logout, deleteUser, currentUser, storageUser } = useAuth()
  const { isTextsLoading, txt, changeLanguage, language } = useTexts('profile')
  const { isStylesLoading, style } = useStyles('registrationStyles')
  const { sendMessageToVista } = useMessenger()

  const [error, setError] = useState('')
  const [user, setUser] = useState(undefined)
  const [lectures, setLectures] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isChanging, setIsChanging] = useState(false)
  const [csvData, setCsvData] = useState(undefined)
  const [csvFilename, setCsvFilename] = useState(undefined)
  const [isGenerating, setIsGenerating] = useState(false)

  useEffect(() => {
    getDoc(doc(db, 'users', currentUser.email))
      .then((snapshot) => {
        setUser(snapshot.data())
        setIsLoading(false)
      })
      .catch((e) => setError(e.message))
  }, [db, doc, getDoc, currentUser.email, getDocs, collection])

  useEffect(() => {
    if (lectures.length > 0) {
      lectures.forEach((presenter) => {
        presenter.chapters.forEach((chapter) => {
          const foo = chapter.id.split('_')
          const idx = foo[foo.length - 1]
          headers.push({
            key: chapter.id,
            label: `${presenter.presenter} ${idx}`,
          })
        })
      })
    }
  }, [lectures])

  const getUsers = useCallback(async () => {
    const res = []
    await getDocs(collection(db, 'users')).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        const data = {
          lastName: '',
          firstName: '',
          email: '',
          gender: '',
          language: '',
          birthDate: '',
          signupDate: '',
          riziv: '',
          newsletter: '',
          downloads: [],
          logins: ['init'],
          ...doc.data(),
        }
        res.push({ email: doc.id, ...data })
      }),
    )
    return res
  }, [collection, db, getDocs])

  const generateCsv = async () => {
    setIsGenerating(true)

    const ls = []
    getDocs(collection(db, 'lectures'))
      .then((querySnapshot) =>
        querySnapshot.forEach((doc) => {
          ls.push({ id: doc.id, ...doc.data() })
        }),
      )
      .then(async () => {
        setLectures(ls)

        const users = await getUsers()
        users.map((user) => {
          Object.keys(user.progress || {}).forEach((chapter) => {
            user[chapter] = `${user.progress[chapter].progress}%`
          })
          user.visits = user.logins.length
          user.signupDate = user.signupDate.split('T')[0]
          user.newsletter = user.newsletter ? 'yes' : 'no'
          return user
        })
        setCsvData(
          users.sort((a, b) => b.signupDate.localeCompare(a.signupDate)),
        )
        setIsGenerating(false)
        setCsvFilename(
          `${new Date().toISOString().slice(0, 10)}-parkinsonAcademy.csv`,
        )
      })
  }

  const handleLogout = () => {
    setError('')
    logout()
      .then(() => {
        history.push('/login')
        sendMessageToVista({ action: 'do-logout' })
      })
      .catch(() => setError(txt('errorLogout')))
  }

  const deleteAccount = () => {
    const password = window.prompt(txt('confirmDelete'))
    if (!password) {
      return
    }

    const credential = EmailAuthProvider.credential(currentUser.email, password)
    setError('')
    setIsChanging(true)
    reauthenticateWithCredential(currentUser, credential)
      .then(() => {
        setDoc(
          doc(db, 'users', currentUser.email),
          { deleted: true },
          { merge: true },
        )
          .then(() => {
            deleteUser(currentUser).then(() => history.push('/login'))
          })
          .catch(() => setError(txt('errorDelete')))
          .finally(() => setIsChanging(false))
      })
      .catch((e) => setError(txt('errorCredentials')))
      .finally(() => setIsChanging(false))
  }

  return isTextsLoading || isStylesLoading || isLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">{txt('headline')}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="text-center mt-3 w-50 m-auto">
        {user && <>{`${user.firstName} ${user.lastName}`}</>}
        <br />
        <Link to="/update" className="btn btn-primary w-100 mt-3">
          {txt('buttonUpdateAccount')}
        </Link>
        <Link to="/password" className="btn btn-primary w-100 mt-3">
          {txt('buttonUpdate')}
        </Link>
        <Button
          disabled={isChanging}
          className="btn btn-primary w-100 mt-3"
          onClick={deleteAccount}
        >
          {txt('buttonDelete')}
        </Button>
        <hr />
        <Button
          disabled={isChanging}
          className="btn btn-primary w-100"
          onClick={handleLogout}
        >
          {txt('buttonLogout')}
        </Button>
        <hr />
        {(storageUser?.roles || []).includes('dashboard') && (
          <>
            <Button
              className="btn btn-primary w-100"
              onClick={() => changeLanguage(language === 'fr' ? 'nl' : 'fr')}
            >
              {language === 'fr' ? (
                <>
                  NL / <strong>FR</strong>
                </>
              ) : (
                <>
                  <strong>NL</strong> / FR
                </>
              )}
            </Button>
            <Link to="/admin/dashboard" className="btn btn-primary w-100 mt-3">
              Admin
            </Link>
          </>
        )}
        {(storageUser?.roles || []).includes('csv') && (
          <>
            {csvData ? (
              <CSVLink
                data={csvData}
                headers={headers}
                filename={csvFilename}
                className="btn btn-primary w-100 mt-3"
                target="_blank"
              >
                Download CSV data
              </CSVLink>
            ) : (
              <Button
                disabled={isGenerating}
                className="btn btn-primary w-100 mt-3"
                onClick={() => generateCsv()}
              >
                Generate CSV data
              </Button>
            )}
          </>
        )}
      </div>
    </GlobalStyles>
  )
}
