import React, { useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import Styling from './Styling'
import Structure from '../Structure'

// import { firestore } from '../../../../lib/firebase'

export default function Dashboard() {
  // const { db, collection, getDocs } = firestore

  const [rowData, setRowData] = useState([])

  const [columnDefs] = useState([
    { field: 'email', sortable: true, filter: true },
    { field: 'firstName', sortable: true, filter: true },
    { field: 'lastName', sortable: true, filter: true },
    { field: 'birthDate', sortable: true, filter: true },
    { field: 'language', sortable: true, filter: true },
    { field: 'gender', sortable: true, filter: true },
    { field: 'signupDate', sortable: true, filter: true },
  ])

  const getUsers = useCallback(async () => {
    const res = []
    /* await getDocs(collection(db, 'users')).then((querySnapshot) => querySnapshot.forEach((doc) => {
      const data = {
        ...doc.data(),
      }
      res.push({ email: doc.id, ...data })
    })) */
    setRowData(res)
  }, [])

  useEffect(() => getUsers(), [getUsers])

  return (
    <Structure>
      <Structure.ContentHeader>
        <Structure.Section>Welcome</Structure.Section>
        <Structure.Interaction> </Structure.Interaction>
      </Structure.ContentHeader>
      <Structure.Content>
        <Styling>
          <Styling.Card>
            <div
              className="ag-theme-alpine"
              style={{ height: 'calc(100vh - 210px)', width: '100%' }}
            >
              <AgGridReact rowData={rowData} columnDefs={columnDefs} />
            </div>
          </Styling.Card>
        </Styling>
      </Structure.Content>
    </Structure>
  )
}
