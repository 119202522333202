import React, { useRef, useState } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import useTexts from '../../../customHooks/useTexts'
import GlobalStyles from '../../Styling'

export default function ForgotPassword() {
  const emailRef = useRef()

  const { resetPassword } = useAuth()
  const { isTextsLoading, txt } = useTexts('forgot')
  const { isStylesLoading, style } = useStyles('unauthorizedStyles')

  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    setMessage('')
    setError('')
    setLoading(true)
    try {
      resetPassword(emailRef.current.value).finally(() => {
        setMessage(txt('message'))
        setLoading(false)
        setDone(true)
      })
    } catch (e) {
      setMessage(txt('message'))
      setDone(true)
    }
  }

  return isTextsLoading || isStylesLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">{txt('headline')}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      {done ? (
        <Link to="/login" className="w-100 mt-2 btn" type="button">
          {txt('buttonLogin')}
        </Link>
      ) : (
        <>
          <div className="text-center mt-3">
            {txt('subhead')}
            <br />
            <br />
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>{txt('email')}</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100 mt-4" type="submit">
                {txt('resetButton')}
              </Button>
            </Form>
          </div>
          <div className="w-100 text-center mt-3">
            <Link to="/login">{txt('buttonLogin')}</Link>
          </div>
          <div className="w-100 text-center mt-2">
            {txt('account')} <Link to="/signup">{txt('signup')}</Link>
          </div>
        </>
      )}
    </GlobalStyles>
  )
}
