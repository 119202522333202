import { useRef, useState, useCallback, useMemo, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import VideoPlayer from 'react-video-js-player'

import { firestore } from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'

import Wrapper, { VideoContainer, VideoMenu, overlayCss } from './Styling'
import poster from './PA_Screen_Poster.jpeg'

const Video = () => {
  const { vid, chapterId, overlayId, timeIn } = useParams()
  const { currentUser } = useAuth()
  const playerRef = useRef(null)
  const { db, getDoc, doc } = firestore // , addDoc, collection

  const [video, setVideo] = useState()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [time, setTime] = useState(timeIn)

  const [animate, setAnimate] = useState('')
  const [overlayContent, setOverlayContent] = useState(null)

  // console.log('params', { vid, chapterId, overlayId, timeIn })

  useEffect(
    () =>
      getDoc(doc(db, 'videos', vid))
        .then((snapshot) => {
          setVideo(snapshot.data())
          setLoading(false)
        })
        .catch((e) => setError(e.message)),
    [db, doc, getDoc, vid, chapterId, overlayId, timeIn],
  )

  const [videoJsOptions, startTime] = useMemo(() => {
    let st = 0

    if ((video?.chapters || []).length === 0) {
      return [{}, st]
    }

    if (timeIn) {
      st = timeIn
    } else if (overlayId && video.overlays) {
      st = video.overlays.find(({ id }) => id === overlayId)?.show
    } else if (chapterId && video.chapters) {
      st = video.chapters.find(({ id }) => id === chapterId)?.start
    }

    return [
      {
        sources: [video],
      },
      st,
    ]
  }, [video, chapterId, overlayId, timeIn])

  // initialize details
  useEffect(() => {
    if ((video?.chapters || []).length > 0 && playerRef.current) {
      const currentTime = playerRef?.current?.currentTime() || 0
      const currentChapter =
        video.chapters.find(
          (chapter) =>
            parseFloat(chapter.start) <= (currentTime || startTime) &&
            parseFloat(chapter.end) > (currentTime || startTime),
        ) || video.chapters[0]

      playerRef.current.currentTime(startTime)

      if (!currentChapter) {
        playerRef.current.pause()
      }

      // console.log("currentChapter", currentChapter);
      console.log('Change video', { video, currentChapter })
    }
  }, [video, startTime])

  const jumpTo = (destination) => {
    if (destination.time) {
      playerRef.current.currentTime(destination.time)
      return
    }
    if (destination.chapter) {
      const destChapter = video.chapters.find(
        ({ id }) => id === destination.chapter,
      )
      playerRef.current.currentTime(destChapter.start)
      return
    }
  }

  const overlay = useMemo(
    () =>
      !video?.overlays?.length
        ? null
        : video.overlays.find(
            ({ show, hide }) =>
              parseFloat(show) <= time && parseFloat(hide) > time,
          ),
    [time, video?.overlays],
  )

  const handlePlayerReady = useCallback(
    (player) => {
      playerRef.current = player
      // setReady(true)
      player.currentTime(startTime)

      // player.on('play', () => setPlaying(true))
      // player.on('pause', () => setPlaying(false))
      player.on('timeupdate', () => setTime(player.currentTime()))
    },
    [startTime],
  )

  useEffect(() => {
    if (overlay) {
      setOverlayContent(overlay)
      window.setTimeout(() => setAnimate('show'), 300)
    } else {
      setAnimate('')
      window.setTimeout(() => setOverlayContent(null), 300)
    }
  }, [overlay])

  if (error) {
    return (
      <Card>
        <Card.Body>
          <div className="text-center mb-4">{error}</div>
        </Card.Body>
      </Card>
    )
  }

  return loading || !currentUser ? null : (
    <Wrapper>
      <VideoContainer>
        <VideoPlayer
          // https://openbase.com/js/react-video-js-player/documentation
          hideControls={['seekbar', 'fullscreen']}
          autoplay
          controls
          fluid
          responsive
          src={videoJsOptions.sources}
          poster={poster}
          onReady={handlePlayerReady}
          width={1280}
          height={720}
          // onPlay={this.onVideoPlay.bind(this)}
          // onPause={this.onVideoPause.bind(this)}
          // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
          // onSeeking={this.onVideoSeeking.bind(this)}
          // onSeeked={this.onVideoSeeked.bind(this)}
          // onEnd={this.onVideoEnd.bind(this)}
        />
      </VideoContainer>
      <VideoMenu>
        <br />
        <h3 className="text-center" style={{ color: '#786f71' }}>Lecture progress</h3>
      </VideoMenu>
      {overlayContent && (
        <>
          {overlayContent.destination.external && (
            <a
              href={overlayContent.destination.external}
              className={`${overlayCss} ${overlayContent.className} ${animate}`}
              target="_blank"
              rel="noreferrer"
            >
              {overlayContent.text}
            </a>
          )}
          {overlayContent.destination.internal && (
            <Link
              className={`${overlayCss} ${overlayContent.className} ${animate}`}
              to={overlayContent.destination.internal}
            >
              {overlayContent.text}
            </Link>
          )}
          {(overlayContent.destination.time ||
            overlayContent.destination.chapter) && (
            <button
              className={`${overlayCss} ${overlayContent.className} ${animate}`}
              onClick={() => jumpTo(overlayContent.destination)}
            >
              {overlayContent.text}
            </button>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Video

/* const pad = (val) => (val < 10 ? `0${val}` : val)
const secondsToString = (seconds) =>
  `${Math.floor(((seconds % 31536000) % 86400) / 3600)}:${pad(
    Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
  )}:${pad(Math.floor(seconds))}` */

/* useEffect(() => {
    console.log('only once')
    addDoc(collection(db, 'videos'), mockData)
  }, []) */

// initialize video
/*   const [videoJsOptions, name, startTime] = useMemo(() => {
    if (!video?.chapters?.length) {
      return [{}, '', '0']
    }

    const currentChapter = video.chapters.find(({ id }) => id === chapterId)

    return [
      {
        fluid: true,
        controls: true,
        responsive: true,
        autoplay: 'play',
        sources: [video],
        userActions: { hotkeys: true },
      },
      video.name,
      currentChapter?.start || 0,
    ]
  }, [chapterId, video])

  // initialize details
  const { start, head, description, end, stop } = useMemo(() => {
    if (!video?.chapters?.length) {
      return { start: '0', head: '', description: '', end: '0', stop: false }
    }

    const currentTime = playerRef?.current?.currentTime() || 0
    const currentChapter =
      video.chapters.find(
        (chapter) =>
          parseFloat(chapter.start) <= (currentTime || startTime) &&
          parseFloat(chapter.end) > (currentTime || startTime),
      ) || video.chapters[0]

    if (!currentChapter && playerRef?.current) {
      const altChapter =
        video.chapters.find(({ finish }) => finish === true) ||
        video.chapters[0]

      // console.log("no currentChapter", altChapter);
      playerRef.current.pause()
      playerRef.current.currentTime(startTime)
      return altChapter
    }

    // console.log("currentChapter", currentChapter);
    console.log('Change video', { video, currentChapter })

    return currentChapter
  }, [video, startTime]) */

/*
  const style = { textAlign: 'center', width: '25%' }(
    <>
      <div style={{ maxWidth: '1160px', margin: 'auto' }}>
        <VideoJS
          // cons={console.log(videoJsOptions)}
          options={videoJsOptions}
          onReady={handlePlayerReady}
          jumpTo={jumpTo}
          overlay={overlay}
        />
      </div>
      {ready && (
        <table width="100%" className="mt-2">
          <tbody>
            <tr>
              <td style={style}>{name}</td>
              <td style={style}>{head}</td>
              <td style={style}>{description}</td>
            </tr>
            <tr>
              <td style={style}>
                start: {start !== '0' ? `${start}s` : 'begin'}
              </td>
              <td style={style}>end: {stop !== '0' ? `${end}s` : 'end'}</td>
              <td style={style}>time: {secondsToString(playerRef.current.currentTime())}</td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  ) */
