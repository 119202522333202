import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { firestore } from '../../../lib/firebase'
import multiline from '../../../lib/multiline'
import useAuth from '../../../customHooks/useAuth'
import useMessenger from '../../../customHooks/useMessenger'
import Wrapper, {
  Container,
  LectureButton,
  Presenter,
  Description,
} from './Styling'

const downloadsBase = 'https://parkinsonacademy.be/downloads'

export default function Billboard() {
  const [loading, setLoading] = useState(true)
  const { currentUser, storageUser } = useAuth()
  const { lectureId } = useParams()
  const { db, collection, getDocs, query } = firestore
  const [lectures, setLectures] = useState({})
  const { sendMessageToVista } = useMessenger()

  const getLectures = useCallback(async () => {
    const res = []
    await getDocs(query(collection(db, 'lectures'))).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        res.push({ id: doc.id, ...doc.data() })
      }),
    )
    return res
  }, [collection, db, getDocs, query])

  const load = useCallback(async () => {
    setLectures(await getLectures())
    setLoading(false)
  }, [getLectures])

  useEffect(() => {
    if (currentUser) {
      load()
    }
  }, [currentUser, load])

  return loading ? null : (
    <Wrapper>
      {lectures
        .sort((a, b) => a.auditorium.localeCompare(b.auditorium))
        .filter((l) => lectureId ? l.id === lectureId : true )
        .map(({ presenter, description, auditorium, vistaUrl, image }) => (
          <Container key={presenter}>
            <img
              src={`${downloadsBase}/${image}`}
              width="144"
              height="144"
              alt=""
              style={{ margin: '15px' }}
            />
            <div style={{ width: '100%' }}>
              <LectureButton
                onClick={() =>
                  sendMessageToVista({ action: 'go-to', destination: vistaUrl })
                }
              >
                AUDITORIUM {auditorium}
              </LectureButton>
              <Presenter>{presenter}</Presenter>
              <Description>
                {multiline(description[storageUser?.language || 'nl'])}
              </Description>
            </div>
          </Container>
        ))}
    </Wrapper>
  )
}
