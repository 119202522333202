import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Button, Alert, Row, Col } from 'react-bootstrap'

import { firestore, sendEmailVerification } from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import useTexts from '../../../customHooks/useTexts'
import useMessenger from '../../../customHooks/useMessenger'
import GlobalStyles from '../../Styling'

const getRizivValid = (rnum) => {
  const r = rnum.replace(/[^0-9]/g, '')
  const first = parseInt(r.slice(0, 6), 10)
  const second = parseInt(r.slice(-5).slice(0, 2), 10)
  const third = parseInt(r.slice(-3), 10)
  const correctLength = r.length === 11
  const rizivValidOld = parseInt(97 - (first % 97), 10) === second
  const rizivValidNew = parseInt(89 - (first % 89), 10) === second
  const inRange = third < 10 || (third > 759 && third < 781)
  // console.log({ first, second, third })
  // console.log({ correctLength, rizivValidOld, rizivValidNew, inRange })
  return correctLength && (rizivValidOld || rizivValidNew) && inRange
}

export default function Signup() {
  const test = false
  const history = useHistory()

  const newsletterRef = useRef()
  const { db, doc, setDoc, getDoc } = firestore
  const { sendMessageToVista } = useMessenger()
  const { signup, currentUser, logout } = useAuth()
  const { isStylesLoading, style } = useStyles('unauthorizedStyles')
  const { isTextsLoading, language, txt, setLanguage } =
    useTexts('signup')

  const [email, setEmail] = useState(test ? 'alexbueth+test14@gmail.com' : '')
  const [emailConf, setEmailConf] = useState(
    test ? 'alexbueth+test14@gmail.com' : '',
  )
  const [password, setPassword] = useState(test ? 'password@1' : '')
  const [passconf, setPassconf] = useState(test ? 'password@1' : '')
  const [gender, setGender] = useState(test ? 'm' : '')
  const [firstName, setFirstName] = useState(test ? 'Test' : '')
  const [lastName, setLastName] = useState(test ? 'Person' : '')
  const [birthDate, setBirthDate] = useState(test ? '1983-08-03' : '')
  const [riziv, setRiziv] = useState(test ? '1-00799-38-770' : '')
  const [error, setError] = useState(test ? '' : '')
  const [loading, setLoading] = useState(false)
  const [lang, setLang] = useState(language)

  useEffect(() => setLanguage(lang), [lang, setLanguage])

  useEffect(() => {
    if (currentUser) {
      if (currentUser.emailVerified) {
        history.push('/profile')
      } else {
        history.push('/login/verify')
      }
    }
  }, [currentUser, history])

  async function handleSubmit(e) {
    e.preventDefault()

    if (email !== emailConf) {
      return setError(txt('errEmailMatch'))
    }

    if (password.length < 8) {
      return setError(txt('errPasswordLength'))
    }

    /* if (
      !password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)
    ) {
      return setError(txt('errPasswordPattern'))
    } */

    if (password !== passconf) {
      return setError(txt('errPasswordMatch'))
    }

    const newUser = {
      language: lang, // browser and query are recognized but this selection overrides
      email,
      gender,
      firstName,
      lastName,
      birthDate,
      signupDate: new Date().toISOString(),
      riziv,
      newsletter: newsletterRef.current.checked,
    }

    try {
      setError('')
      setLoading(true)

      getDoc(doc(db, 'users', email))
        .then((snapshot) => {
          if (!snapshot.data()) {
            signup(email, password)
              .then(({ user }) => {
                setDoc(doc(db, 'users', newUser.email), newUser).then(() => {
                  sendEmailVerification(user).then(() => {
                    logout().then(() => {
                      setLoading(false)
                      sendMessageToVista({
                        action: 'switch-language',
                        language: lang,
                      })
                      history.push('/login/verify')
                    })
                  })
                })
              })
              .catch(() => setError(txt('errAccountCreate')))
          }
        })
        .catch(() => setError(txt('errAccountCreate')))
    } catch {
      setError(txt('errAccountCreate'))
    }

    setLoading(false)
  }

  return isTextsLoading || isStylesLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">{txt('headline')}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="w-100">
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('email')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4" style={{ marginTop: '-12px' }}>
              {txt('emailConf')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="email"
                value={emailConf}
                onChange={(e) => setEmailConf(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('password')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4" style={{ marginTop: '-12px' }}>
              {txt('passwordConf')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="password"
                value={passconf}
                onChange={(e) => setPassconf(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('languageHead')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                value={lang}
                onChange={(e) => setLang(e.currentTarget.value)}
                required
              >
                <option value="nl">Nederlands</option>
                <option value="fr">Français</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('genderHead')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                value={gender}
                onChange={(e) => setGender(e.currentTarget.value)}
                required
              >
                <option value="">{txt('pleaseChoose')}</option>
                <option value="f">{txt('genderFemale')}</option>
                <option value="m">{txt('genderMale')}</option>
                <option value="n">{txt('genderNeutral')}</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('firstName')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('lastName')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('birthDate')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                placeholder="Birthdate"
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('riziv')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={riziv}
                maxLength={14}
                onChange={(e) => setRiziv(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Col sm="8" className="my-3">
            <Form.Check
              type="checkbox"
              label={txt('newsletterConsent')}
              id="newsletter"
              ref={newsletterRef}
            />
          </Col>
          <Button
            disabled={loading || !getRizivValid(riziv)}
            className="w-100 mt-2"
            type="submit"
          >
            {txt('signUp')}
          </Button>
        </Form>
      </div>
      <div className="w-100 text-center mt-2">
        {txt('account')} <Link to="/login">{txt('logIn')}</Link>
      </div>
    </GlobalStyles>
  )
}
