import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin-top: 20px;
`

export const Container = styled.div`
  border-top: 2px solid #000;
  margin: 10px auto;
  padding-top: 10px;
  width: 1000px;
  &:first-of-type {
    border: 0;
  }
`

export const LectureButton = styled.button`
  margin-top: 5px;
  border-radius: 0;
  background: #98ce01;
  text-decoration: none;
  border: 0;
  width: 40px !important;
  height: 40px;
  padding: 5px 10px;
  width: 50%;
  color: #fff;
  font-weight: 200;
  font-style: italic;

  &:disabled {
    background: #cccccc;
  }

  &[name='active'] {
    background: #0a747f;
  }

  & > div {
    color: #fff;
    font-size: 18px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
    text-transform: uppercase;
    font-style: italic;
  }
`

export const Presenter = styled.div`
  font-size: 20px;
  font-weight: 500px;
  text-align: center;
`

export const Description = styled.div`
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 20px;
`

export default Wrapper
