const lectures = [
  {
    id: 'santens_ziekte',
    vistaUrl: '/lectures/01',
    auditorium: '1',
    presenter: 'Prof. Dr. Patrick Santens',
    biography: 'NEWBiosketch-psantens_jan_2022.pdf',
    description: {
      fr: 'Description fr',
      nl: 'De ziekte van Parkinson:*diagnose en behandeling voor de huisarts',
    },
    chapters: [
      {
        sort: 1,
        id: 'santens_ziekte_01',
        ov: 'santens_nl_2022-03-20/ov_ch1.mp4',
        st: { fr: 'santens_nl_2022-03-20/st_ch1.mp4' },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: {
          fr: 'Chapter 1 fr',
          nl: 'Intro casuistiek',
        },
        conditions: [],
        nextUrl: '/lecture/santens_ziekte/santens_ziekte_02',
      },
      {
        sort: 2,
        id: 'santens_ziekte_02',
        ov: 'santens_nl_2022-03-20/ov_ch2.mp4',
        st: { fr: 'santens_nl_2022-03-20/st_ch2.mp4' },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: {
          fr: 'Chapter 2 fr',
          nl: 'Diagnose',
        },
        conditions: ['santens_ziekte_01'],
        nextUrl: '/lecture/santens_ziekte/santens_ziekte_03',
      },
      {
        sort: 3,
        id: 'santens_ziekte_03',
        ov: 'santens_nl_2022-03-20/ov_ch3.mp4',
        st: { fr: 'santens_nl_2022-03-20/st_ch3.mp4' },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: {
          fr: 'Chapter 3 fr',
          nl: 'Medicamenteuze behandeling',
        },
        conditions: ['santens_ziekte_01', 'santens_ziekte_02'],
        questionaire: [
          {
            show: 1001.5,
            hide: 1043.5,
            fields: [
              {
                content: '1',
                correct: false,
                style: {
                  top: '102px',
                },
              },
              {
                content: '2',
                correct: false,
                style: {
                  top: '175px',
                },
              },
              {
                content: '3',
                correct: true,
                style: {
                  top: '304px',
                },
              },
              {
                content: '4',
                correct: false,
                style: {
                  top: '377px',
                },
              },
              {
                content: '5',
                correct: false,
                style: {
                  top: '451px',
                },
              },
              {
                content: '6',
                correct: false,
                style: {
                  top: '523px',
                },
              },
            ],
          },
        ],
      },
    ],
    quiz: [
      {
        sort: 1,
        question: {
          nl: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
          fr: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        },
        answers: [
          {
            sort: 1,
            nl: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            fr: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            correct: false,
            button: 'A',
          },
          {
            sort: 2,
            nl: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            fr: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            correct: true,
            button: 'B',
          },
          {
            sort: 3,
            nl: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            fr: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            correct: false,
            button: 'C',
          },
        ],
      },
    ],
  },
  {
    id: 'franco_maladie',
    vistaUrl: '/lectures/02',
    auditorium: '2',
    presenter: 'Dr. Gianni Franco',
    description: {
      fr: 'La maladie de Parkinson:*détection et prise en charge des signes non-moteurs',
      nl: 'desc nl',
    },
    chapters: [
      {
        sort: 1,
        id: 'franco_maladie_01',
        ov: 'franco_fr_2022-03-18/ov_ch1.mp4',
        st: { nl: 'franco_fr_2022-03-18/st_ch1.mp4' },
        type: 'video/mp4',
        language: 'fr',
        subtitles: ['nl'],
        label: {
          fr: 'Etat des Lieux',
          nl: 'Chapter 1 nl',
        },
        conditions: [],
        nextUrl: '/lecture/franco_maladie/franco_maladie_02',
      },
      {
        sort: 2,
        id: 'franco_maladie_02',
        ov: 'franco_fr_2022-03-18/ov_ch2.mp4',
        st: { nl: 'franco_fr_2022-03-18/st_ch2.mp4' },
        type: 'video/mp4',
        language: 'fr',
        subtitles: ['nl'],
        label: { fr: 'Agitation nocturne', nl: 'Chapter 2 nl' },
        conditions: ['franco_maladie_01'],
        nextUrl: '/lecture/franco_maladie/franco_maladie_03',
      },
      {
        sort: 3,
        id: 'franco_maladie_03',
        ov: 'franco_fr_2022-03-18/ov_ch3.mp4',
        st: { nl: 'franco_fr_2022-03-18/st_ch3.mp4' },
        type: 'video/mp4',
        language: 'fr',
        subtitles: ['nl'],
        label: {
          fr: 'Douleur',
          nl: 'Chapter 3 nl',
        },
        conditions: ['franco_maladie_01', 'franco_maladie_02'],
        nextUrl: '/lecture/franco_maladie/franco_maladie_04',
      },
      {
        sort: 4,
        id: 'franco_maladie_04',
        ov: 'franco_fr_2022-03-18/ov_ch4.mp4',
        st: { nl: 'franco_fr_2022-03-18/st_ch4.mp4' },
        type: 'video/mp4',
        language: 'fr',
        subtitles: ['nl'],
        label: {
          fr: 'Signes NON moteurs',
          nl: 'Chapter 4 nl',
        },
        conditions: [
          'franco_maladie_01',
          'franco_maladie_02',
          'franco_maladie_03',
        ],
        nextUrl: '/lecture/franco_maladie/franco_maladie_05',
      },
      {
        sort: 4,
        id: 'franco_maladie_05',
        ov: 'franco_fr_2022-03-18/ov_ch5.mp4',
        st: { nl: 'franco_fr_2022-03-18/st_ch5.mp4' },
        type: 'video/mp4',
        language: 'fr',
        subtitles: ['nl'],
        label: {
          fr: 'Détection précoce',
          nl: 'Chapter 5 nl',
        },
        conditions: [
          'franco_maladie_01',
          'franco_maladie_02',
          'franco_maladie_03',
          'franco_maladie_04',
        ],
      },
    ],
  },
]

export default lectures

/*
  {
    id: 'test_video',
    url: '/lectures/01',
    where: 'AUDITORIUM 1',
    presenter: 'Prof. Dr. Bueth-Software',
    description: {
      fr: 'Description FR',
      nl: 'Description NL',
    },
    chapters: [
      {
        sort: 1,
        id: 'test_video_01',
        ov: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        st: {
          fr: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: {
          fr: 'Chapter 1 fr',
          nl: 'Chapter 1 nl with a very long title that needs more space',
        },
        questionaire: [
          {
            show: '0:05',
            hide: '3:00',
            fields: [
              {
                content: '1',
                correct: false,
                style: {
                  top: '50px',
                  left: '50px',
                  width: '50px',
                  height: '50px',
                },
              },
              {
                content: '2',
                correct: false,
                style: {
                  top: '150px',
                  left: '50px',
                  width: '50px',
                  height: '50px',
                },
              },
              {
                content: '3',
                correct: true,
                style: {
                  top: '250px',
                  left: '50px',
                  width: '50px',
                  height: '50px',
                },
              },
              {
                content: '4',
                correct: false,
                style: {
                  top: '350px',
                  left: '50px',
                  width: '50px',
                  height: '50px',
                },
              },
              {
                content: '5',
                correct: false,
                style: {
                  top: '450px',
                  left: '50px',
                  width: '50px',
                  height: '50px',
                },
              },
              {
                content: '6',
                correct: false,
                style: {
                  top: '550px',
                  left: '50px',
                  width: '50px',
                  height: '50px',
                },
              },
            ],
          },
        ],
        conditions: [],
        nextUrl: '/lecture/test_video/test_video_02',
      },
      {
        sort: 2,
        id: 'test_video_02',
        ov: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        st: {
          fr: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: { fr: 'Chapter 2 fr', nl: 'Chapter 2 nl' },
        conditions: ['test_video_01'],
        nextUrl: '/lecture/test_video/test_video_03',
      },
      {
        sort: 3,
        id: 'test_video_03',
        ov: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        st: {
          fr: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: { fr: 'Chapter 3 fr', nl: 'Chapter 3 nl' },
        conditions: ['test_video_01', 'test_video_02'],
        nextUrl: '/lecture/test_video/test_video_04',
      },
      {
        sort: 4,
        id: 'test_video_04',
        ov: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        st: {
          fr: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: { fr: 'Chapter 4 fr', nl: 'Chapter 4 nl' },
        conditions: ['test_video_01', 'test_video_02', 'test_video_03'],
        nextUrl: '/lecture/test_video/test_video_05',
      },
      {
        sort: 5,
        id: 'test_video_05',
        ov: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        st: {
          fr: 'https://parkinsonacademy.be/video/test/TestVideo_EXTRA_1.mp4',
        },
        type: 'video/mp4',
        language: 'nl',
        subtitles: ['fr'],
        label: { fr: 'Chapter 5 fr', nl: 'Chapter 5 nl' },
        conditions: [
          'test_video_01',
          'test_video_02',
          'test_video_03',
          'test_video_04',
        ],
      },
    ],
  },
 */
