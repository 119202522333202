import React, { useRef, useState } from 'react'
import { Form, Button, Alert, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { reauthenticateWithCredential, auth } from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import useTexts from '../../../customHooks/useTexts'
import GlobalStyles from '../../Styling'

export default function Update() {
  const passwordOldRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { EmailAuthProvider } = auth
  const { isTextsLoading, txt } = useTexts('changePassword')
  const { isStylesLoading, style } = useStyles('registrationStyles')
  const { updatePassword, currentUser } = useAuth()
  const [isChanging, setIsChanging] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault()
    const password = passwordRef.current.value

    if (password !== passwordConfirmRef.current.value) {
      return setError(txt('errorDontMatch'))
    }

    if (password.length < 8 || password.length > 16) {
      return setError(txt('errPasswordLength'))
    }

    if (
      !password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)
    ) {
      return setError(txt('errPasswordPattern'))
    }

    const credential = EmailAuthProvider.credential(
      currentUser.email,
      passwordOldRef.current.value,
    )
    setIsChanging(true)
    reauthenticateWithCredential(currentUser, credential)
      .then(() => {
        setError('')
        updatePassword(passwordRef.current.value)
          .then(() => history.push('/profile'))
          .catch((e) => setError(txt('error')))
          .finally(() => setIsChanging(false))
      })
      .catch((e) => setError(txt('error')))
      .finally(() => setIsChanging(false))
  }

  return isStylesLoading || isTextsLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">{txt('headline')}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <div style={{ maxWidth: '400px', margin: 'auto' }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group id="password-old">
            <Form.Label>{txt('passwordOld')}</Form.Label>
            <Form.Control type="password" ref={passwordOldRef} required />
          </Form.Group>
          <Form.Group id="password">
            <Form.Label>{txt('password')}</Form.Label>
            <Form.Control type="password" ref={passwordRef} required />
          </Form.Group>
          <Form.Group id="password-confirm">
            <Form.Label>{txt('passwordConfirm')}</Form.Label>
            <Form.Control type="password" ref={passwordConfirmRef} required />
          </Form.Group>
          <Row>
            <Col xs={6}>
              <Link to="/profile" className="btn btn-secondary w-100 mt-4">
                {txt('cancel')}
              </Link>
            </Col>
            <Col xs={6}>
              <Button disabled={isChanging} className="w-100 mt-4" type="submit">
                {txt('action')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </GlobalStyles>
  )
}
