import { useState, useCallback, useMemo, useEffect, Fragment } from 'react'
import { Card } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import VideoPlayer from 'react-video-js-player'

import { firestore } from '../../../lib/firebase'
import multiline from '../../../lib/multiline'
import useAuth from '../../../customHooks/useAuth'

import Wrapper, {
  VideoContainer,
  VideoMenu,
  Presenter,
  Description,
  LectureButton,
  LectureLink,
  Chapters,
  Accreditation,
  Duration,
  Extra,
  overlayCss,
} from './Styling'
import poster from './PA_Screen_Poster.jpeg'

import useTexts, {
  validLanguages,
  defaultLanguage,
} from '../../../customHooks/useTexts'

// import lecturesMock from '../../../lib/lectures'

const videoBase = 'https://parkinsonacademy.be/videos'
const downloadsBase = 'https://parkinsonacademy.be/downloads'

const getMinutes = (duration) => {
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

const Video = () => {
  const history = useHistory()

  const { lectureId, chapterId } = useParams()
  const { currentUser, storageUser, refreshStorageUser } = useAuth()
  const { db, doc, setDoc, getDoc } = firestore
  const { txt, isTextsLoading, language } = useTexts('lecture')

  const [error, setError] = useState('')
  const [settings, setSettings] = useState(undefined)
  const [time, setTime] = useState(undefined)
  const [video, setVideo] = useState(undefined)
  const [lecture, setLecture] = useState(undefined)
  const [chapter, setChapter] = useState(undefined)
  const [progress, setProgress] = useState({})
  const [loading, setLoading] = useState(true)
  const [autoplay, setAutoplay] = useState(true)
  const [hideControls, setHideControls] = useState(['fullscreen', 'seekbar'])

  const [animate, setAnimate] = useState('')
  const [overlayContent, setOverlayContent] = useState(null)

  // setDoc(doc(db, 'lectures', 'santens_ziekte'), lecturesMock[0])

  const unveil = useCallback(() => {
    const elements = document.querySelectorAll('button[data-correct]')
    if (elements.length > 0) {
      elements.forEach((e) => {
        if (e.getAttribute('data-correct') === 'yes') {
          e.setAttribute('name', 'active')
        } else {
          e.setAttribute('disabled', true)
        }
      })
    }
  }, [])

  const overlay = useMemo(
    () =>
      !chapter?.questions?.[language]?.length
        ? null
        : chapter.questions[language].find(
            ({ show, hide }) =>
              parseFloat(show) <= time && parseFloat(hide) > time,
          ),
    [chapter?.questions, language, time],
  )

  // console.log(language, overlay)

  const [quizEnabled, buttonsEnabled] = useMemo(() => {
    const enabled = []
    const buttons = { chapterId: true }
    if (
      progress &&
      lecture &&
      lecture?.chapters &&
      lecture?.chapters?.length > 0 &&
      chapter?.id
    ) {
      lecture.chapters.forEach(({ id, conditions }) => {
        const complete = !!progress?.[id]?.complete
        enabled.push(complete)
        buttons[id] = complete
        const con = conditions || []
        con.forEach((condition) => {
          buttons[id] = !!progress?.[condition]?.complete
        })
      })
      const cons = chapter?.conditions || []
      cons.forEach((condition) => {
        buttons[condition] = !!progress?.[condition]?.complete
      })
    }
    return [enabled.every((cx) => cx), buttons]
  }, [chapter?.conditions, chapter?.id, lecture, progress])

  const changeVideo = useCallback(
    (url) => {
      setVideo(undefined)
      history.push(url)
    },
    [history],
  )

  const handlePlayerEnd = useCallback(() => {
    setProgress((current) => ({
      ...current,
      [chapterId]: {
        elapsed: current[chapterId].duration,
        duration: current[chapterId].duration,
        progress: 100,
      },
    }))
  }, [chapterId])

  const handlePlayerReady = useCallback(
    (player) => {
      player.currentTime(time)
      player.on('timeupdate', () => {
        const ct = player.currentTime()
        setTime(ct)
        const duration = player.duration()
        if (duration) {
          setProgress((current) => {
            const p = current[chapterId] || {}
            if (p?.complete === true) {
              p.duration = duration
              p.elapsed = duration
              p.progress = 100
              p.complete = true
            } else {
              p.duration = duration
              p.elapsed = parseInt(ct, 10)
              p.progress = parseInt((p.elapsed / p.duration) * 100, 10)
              if (p.progress === 100) {
                p.complete = true
              }
            }
            return {
              ...current,
              [chapterId]: p,
            }
          })
        }
      })
    },
    [chapterId, time],
  )

  const addDownload = (filename) => {
    const downloads = storageUser.downloads || []
    if (!downloads.includes(filename)) {
      downloads.push(filename)
      setDoc(
        doc(db, 'users', currentUser.email),
        { downloads },
        { merge: true },
      ).then(() => refreshStorageUser())
    }
    return true
  }

  useEffect(() => {
    if (chapter) {
      const l = validLanguages.includes(language) ? language : defaultLanguage
      const vl = chapter?.language === l ? chapter?.ov : chapter?.st[l]
      const src = (vl || '').startsWith('http') ? vl : `${videoBase}/${vl}`
      setVideo({ src, type: chapter?.type })
    }
  }, [chapter, language])

  useEffect(() => {
    if (loading) {
      setLoading(false)
      return
    }
    if (progress && Object.keys(progress).length > 0 && progress[chapterId]) {
      if (
        parseInt(progress[chapterId].elapsed, 10) % 5 === 0 ||
        progress[chapterId].progress === 100
      ) {
        if (
          progress[chapterId].progress === 100 &&
          !progress[chapterId].complete
        ) {
          setProgress((current) => ({
            ...current,
            [chapterId]: {
              ...current[chapterId],
              complete: true,
            },
          }))
          if (chapter?.nextUrl) {
            window.setTimeout(() => changeVideo(chapter?.nextUrl), 1000)
          }
          return
        }
        setDoc(
          doc(db, 'users', currentUser.email),
          { progress },
          { merge: true },
        ).then(() => {
          if (
            progress[chapterId].progress === 100 &&
            !progress[chapterId].complete &&
            chapter?.nextUrl
          ) {
            changeVideo(chapter?.nextUrl)
          }
        })
      }
    }
  }, [
    progress,
    chapterId,
    loading,
    setDoc,
    doc,
    db,
    currentUser.email,
    chapter?.nextUrl,
    changeVideo,
  ])

  useEffect(() => {
    if (storageUser) {
      const sp = storageUser.progress || {}
      setProgress((current) => ({ ...current, ...sp }))

      if (sp?.[chapterId]?.complete) {
        setTime(0)
        setHideControls(['fullscreen'])
        setAutoplay(false)
      } else {
        setTime(sp[chapterId] ? sp[chapterId].elapsed : 0)
      }
    }
  }, [chapterId, storageUser])

  useEffect(() => {
    getDoc(doc(db, 'config', 'settings'))
      .then((snapshot) => {
        setSettings(snapshot.data())
        getDoc(doc(db, 'lectures', lectureId))
          .then((snapshot) => {
            const l = snapshot.data()
            setLecture(l)
            setChapter((l?.chapters || []).find((c) => c.id === chapterId))
            setLoading(false)
          })
          .catch((e) => setError(e.message))
      })
      .catch((e) => setError(e.message))
  }, [chapterId, db, doc, getDoc, lectureId])

  useEffect(() => {
    if (overlay) {
      setOverlayContent(overlay)
      window.setTimeout(() => setAnimate('show'), 300)
    } else {
      setAnimate('')
      window.setTimeout(() => setOverlayContent(null), 300)
    }
  }, [overlay])

  if (error) {
    return (
      <Card>
        <Card.Body>
          <div className="text-center mb-4">{error}</div>
        </Card.Body>
      </Card>
    )
  }

  if (
    loading ||
    isTextsLoading ||
    !settings ||
    !currentUser ||
    !storageUser ||
    !lecture ||
    !chapter ||
    !video ||
    !progress ||
    time === undefined
  ) {
    return (
      <Wrapper>
        <img src={poster} width={1280} height={720} alt="video" />
        <VideoMenu>&nbsp;</VideoMenu>
      </Wrapper>
    )
  }

  // console.log({lecture, chapter, video})
  // console.log(video)
  // console.log(progress[chapterId])

  const hc =
    (storageUser?.roles || []).includes('seekbar') || !settings.forcedViewing
      ? ['fullscreen'] // remove seekbar from hideControls if roles contains seekbar
      : hideControls

  return (
    <Wrapper>
      <VideoContainer>
        {video && (
          <VideoPlayer // https://openbase.com/js/react-video-js-player/documentation
            fluid
            controls
            responsive
            width={1280}
            height={720}
            poster={poster}
            src={video.src}
            type={video.type}
            hideControls={hc}
            autoplay={autoplay} //={false}
            onEnd={handlePlayerEnd}
            onReady={handlePlayerReady}
          />
        )}
      </VideoContainer>
      <VideoMenu>
        <Presenter>{lecture.presenter}</Presenter>

        <Description>
          {multiline(lecture.description[language] || lecture.description.nl)}
        </Description>

        <Chapters>
          {lecture.chapters
            .sort((a, b) => a.sort - b.sort)
            .map(({ label, id, duration }, idx) => (
              <Fragment key={`${id}-${language}`}>
                <LectureButton
                  type="button"
                  disabled={!buttonsEnabled[id] && settings.forcedViewing}
                  name={id === chapterId ? 'active' : undefined}
                  onClick={
                    id === chapterId
                      ? () => {}
                      : () => changeVideo(`/lecture/${lectureId}/${id}`)
                  }
                >
                  <div>{`${idx + 1}. ${label[language] || label.nl}`}</div>
                </LectureButton>
                <Duration>{getMinutes(duration)} m</Duration>
              </Fragment>
            ))}
        </Chapters>
        {settings.accreditation ? (
          <Accreditation>
            <LectureButton
              disabled={!quizEnabled}
              type="button"
              onClick={() => alert('link missing')}
            >
              <div>{txt('accreditation')}</div>
            </LectureButton>
          </Accreditation>
        ) : (
          <div style={{ height: '65px' }} />
        )}
        <Extra>
          {lecture.biography ? (
            <LectureLink
              target="_blank"
              onClick={() => addDownload(lecture.biography)}
              href={`${downloadsBase}/${lecture.biography}`}
              download
            >
              <div>{txt('biography')}</div>
            </LectureLink>
          ) : (
            <LectureButton type="button" disabled>
              <div>{txt('biography')}</div>
            </LectureButton>
          )}
          <LectureButton
            type="button"
            onClick={() =>
              alert(`
• Prof. Dr. P. Santens and Dr. G. Franco received financial support from Zambon for the recording of the webinar and their scientific expertise on the ParkinsonAcademy.be platform.\n\n
• The content of the webinars reflects the personal opinions of both speakers.`)
            }
          >
            <div>{txt('disclaimer')}</div>
          </LectureButton>
          {/* <LectureButton
            type="button"
            onClick={() => changeLanguage(language === 'fr' ? 'nl' : 'fr')}
          >
            {language === 'fr' ? (
              <div>
                NL / <strong>FR</strong>
              </div>
            ) : (
              <div>
                <strong>NL</strong> / FR
              </div>
            )}
          </LectureButton> */}
        </Extra>
      </VideoMenu>

      {overlayContent &&
        overlayContent.fields.map((field, idx) => (
          <button
            key={`${field.content}-${idx}`}
            className={`${overlayCss} ${animate} questionaire`}
            onClick={() => unveil()}
            style={field.style}
            data-correct={field.correct ? 'yes' : 'no'}
          >
            {field.content}
          </button>
        ))}
    </Wrapper>
  )
}

export default Video
