import React, { useState, useCallback, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'

// import { firestore } from '../../../lib/firebase'
import multiline from '../../../lib/multiline'
import useAuth from '../../../customHooks/useAuth'
// import useMessenger from '../../../customHooks/useMessenger'
import Wrapper, {
  Container,
  LectureButton,
  Presenter,
  Description,
} from './Styling'

import mockLectures from '../../../lib/lectures'

export default function Billboard() {
  const [loading, setLoading] = useState(true)
  const { currentUser, storageUser } = useAuth()
  // const { db, collection, getDocs, query } = firestore
  const [lectures, setLectures] = useState({})

  /* const getLectures = useCallback(async () => {
    const res = []
    await getDocs(query(collection(db, 'lectures'))).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        res.push({ id: doc.id, ...doc.data() })
      }),
    )
    return res
  }, [collection, db, getDocs, query]) */

  const load = useCallback(async () => {
    setLectures(mockLectures) // await getLectures())
    setLoading(false)
  }, []) // getLectures])

  useEffect(() => {
    if (currentUser) {
      load()
    }
  }, [currentUser, load])

  return loading ? null : (
    <Wrapper>
      <Presenter>{lectures[0].presenter}</Presenter>
      <Description>
        {multiline(lectures[0].description[storageUser?.language || 'nl'])}
      </Description>
      <Container>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={11}><h3>Question #1</h3></Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={1}>&nbsp;</Col>
          <Col xs={11}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={1}><LectureButton>A</LectureButton></Col>
          <Col xs={11}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={1}><LectureButton>B</LectureButton></Col>
          <Col xs={11}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
