import { useEffect } from 'react'
import { firestore } from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'
import useMessenger from '../../../customHooks/useMessenger'

export default function Welcome() {
  const { currentUser } = useAuth()
  const { db, doc, getDoc } = firestore
  const { sendMessageToVista } = useMessenger()

  useEffect(
    () => {
      if (!currentUser) {
        sendMessageToVista({ action: 'validate', user: false })
        return
      }
      getDoc(doc(db, 'users', currentUser.email))
        .then((snapshot) => {
          sendMessageToVista({ action: 'validate', user: snapshot.data() })
        })
        .catch(() => sendMessageToVista({ action: 'reload' }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return <div />
}
