import React, { useEffect, useState, useCallback } from 'react'
import { Route, Redirect } from 'react-router-dom'
import useAuth from '../../../customHooks/useAuth'

import { firestore } from '../../../lib/firebase'

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  const { db, collection, getDocs, query, where } = firestore
  const [, setNews] = useState(undefined)

  // console.log(currentUser?.email)

  const getNews = useCallback(async () => {
    const res = []
    const q = query(
      collection(db, 'users'),
      where('email', '==', currentUser.email),
      /* where('date', '>=', new Date().toISOString().slice(0, 10)),
      where('online', '==', true),
      orderBy('date', 'desc'),
      limit(13) */
    )
    await getDocs(q).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        const data = {
          date: '', // immer leer initialisieren
          begin: '',
          online: false,
          ...doc.data(),
        }
        res.push({ id: doc.id, ...data })
      }),
    )
    return res
    // .sort((a, b) => a.date.localeCompare(b.date) || a.begin.localeCompare(b.begin) || a.name.localeCompare(b.name))
  }, [collection, currentUser, db, getDocs, query, where])

  const load = useCallback(async () => {
    const news = await getNews()
    setNews(news)
  }, [getNews])

  useEffect(() => {
    if (currentUser) {
      return load()
    }
  }, [currentUser, load])

  /* console.log(news) */
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          return Component ? (
            <Component {...props} />
          ) : (
            <Redirect to="/admin/dashboard" />
          )
        }

        return <Redirect to="/login" />
      }}
    ></Route>
  )
}
