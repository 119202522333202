import React, { useState, useEffect } from 'react'
import { Form, Button, Alert, Row, Col } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { firestore } from '../../../lib/firebase'
import useAuth from '../../../customHooks/useAuth'
import useStyles from '../../../customHooks/useStyles'
import useTexts from '../../../customHooks/useTexts'
import GlobalStyles from '../../Styling'

export default function Update() {
  const { db, doc, setDoc, getDoc } = firestore
  const { isTextsLoading, txt, changeLanguage, language } = useTexts('signup')
  const { isStylesLoading, style } = useStyles('registrationStyles')
  const { currentUser } = useAuth()
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()

  const [gender, setGender] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [newsletter, setNewsletter] = useState(false)

  useEffect(() => {
    getDoc(doc(db, 'users', currentUser.email)).then((snapshot) => {
      if (snapshot.data()) {
        const user = snapshot.data()
        setGender(user.gender)
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setBirthDate(user.birthDate)
        setNewsletter(user.newsletter || false)
      }
    })
  }, [currentUser.email, db, doc, getDoc])

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSaving(true)
    setError('')
    setDoc(
      doc(db, 'users', currentUser.email),
      {
        gender,
        firstName,
        lastName,
        birthDate,
        newsletter,
      },
      { merge: true },
    )
      .then(() => {
        setIsSaving(false)
        history.push('/profile')
      })
      .catch((e) => {
        setIsSaving(false)
        setError(txt('error'))
      })
  }

  return isStylesLoading || isTextsLoading ? null : (
    <GlobalStyles style={style}>
      <h2 className="text-center">{txt('headlineChange')}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="w-100">
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('lastName')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('firstName')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('genderHead')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                value={gender}
                onChange={(e) => setGender(e.currentTarget.value)}
                required
              >
                <option value="f">{txt('genderFemale')}</option>
                <option value="m">{txt('genderMale')}</option>
                <option value="n">{txt('genderNeutral')}</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('birthDate')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                placeholder="Birthdate"
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.currentTarget.value)}
                required
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="my-2">
            <Form.Label column sm="4">
              {txt('changeLanguage')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                value={language}
                onChange={(e) => changeLanguage(e.currentTarget.value)}
                required
              >
                <option value="nl">Nederlands</option>
                <option value="fr">Français</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <hr />
          <Row>
            <Col xs={4}>{txt('newsletterConsent')}</Col>
            <Col xs={8}>
              <Form.Check
                type="checkbox"
                id="newsletter"
                onChange={(e) => setNewsletter(e.target.checked)}
                checked={newsletter}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={6}>
              <Link to="/profile" className="btn btn-secondary w-100 mt-2">
                {txt('cancel')}
              </Link>
            </Col>
            <Col xs={6}>
              <Button disabled={isSaving} className="w-100 mt-2" type="submit">
                {txt('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </GlobalStyles>
  )
}
